import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import img2 from "../images/Source_File_Mark 1 4.png"
import vector from "../images/sblogo.png"
import Img from "gatsby-image"
import ContactSection from "../components/map"
import Footer from "../components/footer"

const about = probs => {
  return (
    <Layout>
      <Helmet>
        <title>Spice Brew | Welcome</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="At Spice Brew we love Indian food. We have grown up with it. But sometimes it upsets us because we know it shouldn't taste like a greasy spoon overpowered with the heat of chili. So we set out to create something new that reminded us of the flavours we love, but also made us as excited as we were the first time we tried Western foods."
        />
        <meta
          name="keywords"
          content="about spice brew, best indian restaurant in london"
        />
        <link rel="canonical" href="https://spice-brew.com/about" />
      </Helmet>
      <section
        style={{
          background:
            "linear-gradient(99.67deg, #97D700 47.1%, #97D700 131.38%)",
        }}
      >
        <div className="grid md:grid-cols-3 grid-cols-2">
          <div className="flex items-center justify-center">
            <div className="w-full">
              <Img
                fluid={probs.data.coverImgOne.childImageSharp.fluid}
                className=""
              />
            </div>
          </div>
          <div className="md:col-span-2 flex  items-center">
            <img
              src={img2}
              alt=""
              className="z-10  h-14 sm:h-20 md:h-28 lg:h-36 2xl:h-56"
            />

            <h1
              className="2xl:text-6xl lg:text-5xl md:text-3xl sm:text-2xl text-base text-white Moderat-Black sm:w-4/6 lg:w-3/6 md:w-3/6 w-auto"
              style={{
                textShadow: " 0px 8.42389px 16.8478px rgba(0, 0, 0, 0.5)",
              }}
            >
              ABOUT US{" "}
            </h1>
          </div>
        </div>
      </section>

      <section className="bg-white flex justify-center">
        <div className="xl-max-w-1200 lg:px-20 md:px-14 sm:px-12 px-8  lg:my-20 md:my-12 my-8">
          <p className="xl:text-xl lg:text-lg sm:text-base text-sm Moderat-Regular mb-4">
            At Spice Brew we love Indian food. We have grown up with it. But
            sometimes it upsets us because we know it shouldn't taste like a
            greasy spoon overpowered with the heat of chili. So we set out to
            create something new that reminded us of the flavours we love, but
            also made us as excited as we were the first time we tried Western
            foods.{" "}
          </p>
          <img src={vector} alt="" className="w-20 md:w-auto" />
          <h1 className="text-black Moderat-bold lg:text-4xl sm:text-3xl text-2xl">
            “A respectful, very modern approach to Indian food”
          </h1>
          <p className="Moderat-Regular sm:text-base lg;text-lg text-sm my-3">
            The result is a respectful, very modern approach to Indian food. We
            have taken traditional ingredients and created a menu of new dishes
            and reworks of some classics. What we have is a true fusion of East
            meets West where the flavours come together perfectly. Take our
            Spice Brew Chicken Wings. There's nothing particularly exciting or
            Indian about the name, but we marinade the chicken in a delicate mix
            of yogurt and spices to give the chicken a fantastic flavour.
          </p>
          <p className="Moderat-Regular sm:text-base lg;text-lg text-sm my-3">
            We wanted to go deeper than that though. If you're going to do it,
            do it right! We had read a lot about the health benefits of the
            spices and other ingredients that are used in traditional Indian
            cuisine and we wanted to use this knowledge to bring you more
            healthy food that is as good as it tastes. For example cinnamon can
            be used to lower blood sugar, turmeric can be used to reduce
            inflammation and cloves are known as a powerful antiviral. These
            ingredients not only provide a potential for a healthier body but
            also provide our food with its great taste.
          </p>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </Layout>
  )
}

export default about
export const pageQuery = graphql`
  query {
    coverImgOne: file(relativePath: { eq: "Rectangle-34.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgTwo: file(relativePath: { eq: "Rectangle 35.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    coverImgThree: file(relativePath: { eq: "Rectangle 34.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bgImg: file(relativePath: { eq: "Rectangle-40.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
